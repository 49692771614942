import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';

import { AVAILABLE_LANGUAGES } from '@/constants/i18n';
import '@/i18n/dayjs';

// Phrase requires `id-id` and `de-de` as the locale code (https://support.phrase.com/hc/en-us/articles/5818281650204-Languages-and-Locales-Strings),
// and Auth0 requires `id` and `de` (https://auth0.com/docs/customize/internationalization-and-localization/universal-login-internationalization).
// To satisfy both requirements, we would set `id` as one of the app language while we import from `id-id` that's created by Phrase.
import { de } from './de-de';
import { en } from './en';
import { id } from './id-id';
import { it } from './it';
import { ptBr } from './pt-BR';
import { zhCn } from './zh-CN';
import { zhHk } from './zh-HK';

export const icu = new ICU({
  bindI18n: 'languageChanged',
  parseLngForICU: (lng) => {
    return lng === 'en' ? 'en-GB' : lng;
  },
  memoize: true,
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(icu)
  .init({
    resources: {
      en,
      'zh-HK': zhHk,
      'zh-CN': zhCn,
      id,
      'pt-BR': ptBr,
      de: de,
      it,
    },
    fallbackLng: 'en',
    defaultNS: 'common',
    fallbackNS: 'common',
    supportedLngs: AVAILABLE_LANGUAGES,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
    keySeparator: '.',
    detection: {
      order: ['path', 'localStorage', 'navigator'],
    },
    postProcess: ['phraseInContextEditor'],
  });

const ICU_CONFIGS = {
  'zh-HK': {
    number: {},
  },
  'pt-BR': {
    number: {},
  },
  id: {
    number: {},
  },
  en: {
    number: {},
  },
  'zh-CN': {
    number: {},
  },
  de: {
    number: {},
  },
  it: {
    number: {},
  },
} as const;

export const getICUConfig = (lng: string) => {
  return (
    ICU_CONFIGS[lng as keyof typeof ICU_CONFIGS] ||
    ICU_CONFIGS['en' as keyof typeof ICU_CONFIGS]
  );
};

icu.addUserDefinedFormats(getICUConfig(i18n.language));

export default i18n;
