import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/id';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { DAYJS_CONFIGS } from '@/i18n/dayjs/constants';

import i18n from '../i18next';

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

dayjs.locale(i18n.language.toLowerCase());
dayjs.updateLocale('en', DAYJS_CONFIGS['en']);
dayjs.updateLocale('zh-cn', DAYJS_CONFIGS['zh-cn']);
dayjs.updateLocale('zh-hk', DAYJS_CONFIGS['zh-hk']);
dayjs.updateLocale('pt-br', DAYJS_CONFIGS['pt-br']);
dayjs.updateLocale('id', DAYJS_CONFIGS['id']);
dayjs.updateLocale('de', DAYJS_CONFIGS['de']);
dayjs.updateLocale('it', DAYJS_CONFIGS['it']);

// TODO: default export dayjs from here
